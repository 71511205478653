window.ghs = Object();
window.ghs.payLoads = undefined;

window.ghs.stopPayLoad = function () {
  clearInterval(window.ghs.payLoads);
  window.ghs.payLoads = undefined;
}

window.ghs.ghs_load_payment_info = function (link_path) {
  $.ajax({
    type: "GET",
    cache: false,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
    },
    url: link_path,
    success: function (html) {
      $('#pay_info').html(html)
    },
    error: function (error) {
      console.log('error', error);
    }
  });
}

$(document).on('click', '.stripe-checkout-button', function (event) {
  let this_button = $(this);
  this_button.attr('disabled','disabled');
  fetch(this_button.data('link'), {method: 'POST'})
    .then(function(response) { return response.json(); })
    .then(function(session) { this_button.removeAttr('disabled'); return stripe.redirectToCheckout({ sessionId: session.id }); })
    .then(function(result) { if (result.error) { this_button.removeAttr('disabled'); alert(result.error.message); }})
    .catch(function(error) { this_button.removeAttr('disabled'); console.error('Error:', error); });
});

$(document).on('change', '#billings_forms_price_variant', function (event) {
  const price_id = $(this).val();
  const price = parseFloat(window.ghs.all_prices.get(price_id));
  const qnt = parseFloat($(this).data('qnt'));

  if (price_id === '') {
    $('.cart_sum').html('');
  } else {
    $('.cart_sum').html(`${price}$ * ${qnt} = ${qnt * price}$`)
  }
});
$(document).on('change', '#subscription_price_id', function (event) {
  const price_id = $(this).val();
  const price = window.ghs.all_prices_title.get(price_id);

  if (price_id === '') {
    $('.price-select').html('');
  } else {
    $('.price-select').html(`<span id="price_1">${price}</span>`)
  }
});

$(document).on('click', '.add-coupon-code', function (event) {
  const url = $(this).data('url');
  const cart = $(this).data('cart');
  const coupon = $('#coupon').val();

  $('.coupon-error').html('');

  $.ajax({
    type: "POST",
    cache: false,
    data: { cart_code: cart, coupon: coupon },
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
    },
    url: url,
    success: function (html) {
      if (html['status']) {
        window.location.reload();
      } else {
        if (html['message']) {
          $('.coupon-error').html(`${html['message']}`);
        } else {
          $('.coupon-error').html(`PROMO CODE <strong>"${coupon}"</strong> NOT FOUND. `);
        }
      }
    },
    error: function (error) {
      $('.coupon-error').html(error);
    }
  });

  return false;
});


$(document).on('submit', '#new_billings_forms', function (event) {
  let valuesToSubmit = $(this).serializeArray();
  const path_url = $(this).attr('action');

  $('input').each(function(index, value) {
    var attb = $(this).attr('required');
    var value = $(this).val();

    if (attb == "required" && value == "") {
      $(this).focus();
      return false;
    }
  });

  window.ghs.disable_form('making a payment..')
  const price_id = $('#billings_forms_price_variant').val();
  stripe.createPaymentMethod({
    type: 'card',
    card: cardNumberElement,
    billing_details: {
      name: `${$('#billings_forms_first_name').val()} ${$('#billings_forms_last_name').val()}`,
      address: {
        city: $('#billings_forms_address_city').val(),
        country: $('#billings_forms_address_country').val(),
        line1: $('#billings_forms_address_street').val(),
        line2: $('#billings_forms_address_street_line_two').val(),
        postal_code: $('#billings_forms_address_zipcode').val(),
        state: $('#billings_forms_address_state').val(),
      },
      email: $('#billings_forms_email').val(),
      phone: $('#billings_forms_phone').val(),
    },
  }).then((result) => {
      if (result.error) {
        window.ghs.show_pay_error(result.error)
        window.ghs.enable_form()
      } else {
        valuesToSubmit.push({name: "paymentId", value: result['paymentMethod']['id']});
        window.ghs.disable_form('making a payment....')
        window.ghs.createSubscription({
          path_url: path_url,
          valuesToSubmit: valuesToSubmit,
          paymentMethodId: result['paymentMethod']['id'],
          priceId: price_id,
        });
      }
    });
  return false; // prevents normal behaviour
});

window.ghs.createSubscription = function ({ path_url, valuesToSubmit, paymentMethodId, priceId }) {
  return (
    $.ajax({
      type: "POST",
      url: path_url,
      data: valuesToSubmit,
      dataType: "JSON"
    }).then((response) => { return response; })
      // If the card is declined, display an error to the user.
      .then((result) => {
        window.ghs.disable_form('making a payment..')
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result;
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the additional details we need.
      .then((result) => {
        window.ghs.disable_form('making a payment...')
        return {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          subscription: result,
        };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      .then(window.ghs.handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail, you
      // get a requires_payment_method error.
      .then(window.ghs.handleRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
      .then(window.ghs.onSubscriptionComplete)
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        window.ghs.enable_form()
        window.ghs.show_pay_error(error)
      })
  );
}

window.ghs.handlePaymentThatRequiresCustomerAction = function ({subscription, invoice, priceId, paymentMethodId, isRetry}) {
  window.ghs.disable_form('making a payment..')
  if (subscription && subscription.status === 'active') {
    window.ghs.disable_form('making a payment....')
    // Subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId };
  }

  // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
  // If it's a retry, the payment intent will be on the invoice itself.
  let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

  if (paymentIntent === null || paymentIntent === undefined) {
    return { subscription, priceId, paymentMethodId };
  }

  window.ghs.disable_form('making a payment...')
  if (paymentIntent.status === 'requires_action' || (isRetry === true && paymentIntent.status === 'requires_payment_method')) {
    window.ghs.disable_form('making a payment.....')
    return stripe.confirmCardPayment(paymentIntent.client_secret, { payment_method: paymentMethodId })
      .then((result) => {
        window.ghs.disable_form('making a payment..')
        if (result.error) {
          // Start code flow to handle updating the payment details.
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc).
          window.ghs.enable_form()
          throw result;
        } else {
          window.ghs.disable_form('making a payment....')
          if (result.paymentIntent.status === 'succeeded') {
            window.ghs.disable_form('making a payment..')
            // Show a success message to your customer.
            return {
              priceId: priceId,
              subscription: subscription,
              invoice: invoice,
              paymentMethodId: paymentMethodId,
            };
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  } else {
    window.ghs.disable_form('making a payment......')
    // No customer action needed.
    return { subscription, priceId, paymentMethodId };
  }
}

window.ghs.handleRequiresPaymentMethod = function ({ subscription, paymentMethodId, priceId }) {
  window.ghs.disable_form('making a payment. .')
  if (subscription && subscription.status === 'active') {
    // subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId };
  } else if (subscription.latest_invoice.payment_intent !== null && subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
    // Using localStorage to manage the state of the retry here,
    // feel free to replace with what you prefer.
    // Store the latest invoice ID and status.
    localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
    localStorage.setItem('latestInvoicePaymentIntentStatus', subscription.latest_invoice.payment_intent.status);
    throw { error: { message: 'Your card was declined.' } };
  } else {
    return { subscription, priceId, paymentMethodId };
  }
}

window.ghs.onSubscriptionComplete = function (result) {
  window.ghs.disable_form('making a payment....')
  console.log('onSubscriptionComplete', result)
  // Payment was successful.
  if (result && result.subscription && ((result.subscription.status === 'active') || (result.subscription.status === 'trialing') || (result.subscription.status === 'incomplete'))) {
    window.ghs.disable_form('payment confirmation...')
    window.ghs.disable_form('create subscription...')
    window.location.href = $('.confirm_link').data('link');
  }
  // if (result.subscription.status === 'incomplete') {
  //   window.ghs.disable_form('payment confirmation incomplete...')
  // }
}

window.ghs.disable_form = function (status_text = 'Loading...') {
  const spinner = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
  $('#submit-button').html(`${spinner} ${status_text}`);
  $('.fieldset-payment-form').prop('disabled', true);
}

window.ghs.enable_form = function () {
  $('#submit-button').html($('#submit-button').data('title'));
  $('.fieldset-payment-form').prop('disabled', false);
}


window.ghs.show_pay_error = function (error) {
  console.log('error', error)
  const msg = (error && error.error && error.error.message) || (error && error.message) || "Payment error"
  $('.errors-pay').html(`<div class="alert alert-danger">${msg}</div>`)
}

window.ghs.sports_update = function (upd_url, select_value) {
  const el_sport = '#subscription_sport';
  const sport_value = $(el_sport).val();
  $.ajax({
    type: "GET",
    cache: false,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
    },
    data: { gender: select_value, sport: sport_value },
    url: upd_url,
    success: function (html) {
      $(el_sport).html(html);
      $(el_sport).selectpicker('refresh');
      $(el_sport).selectpicker('val', sport_value);
    },
    error: function (error) {
      alert('Server error');
    }
  });
}

window.ghs.sport_position_update = function (upd_url, select_value) {
  const el_position = '.subscription_sport_position';
  const el_id_position = '#subscription_sport_position';
  const option_na = '<option value>-- Select --</option>';

  $.ajax({
    type: "GET",
    cache: false,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
    },
    data: { value: select_value },
    url: upd_url,
    success: function (html) {
      if (html.trim() === option_na) {
        $(el_id_position).html(option_na);
        $(el_position).addClass('d-none');
      } else {
        $(el_id_position).html(html);
        $(el_position).removeClass('d-none');
      }
      $(el_id_position).selectpicker('refresh');
    },
    error: function (error) {
      $(el_id_position).html(option_na);
      $(el_position).addClass('d-none');
      alert('Server error');
    }
  });
}
